<template>
    <div class="view">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Comment</div>
        </div>

        <div class="main">
            <div class="l" v-if="loading">
                <loader/>
            </div>
            <comment v-else :comment="comment"/>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

import loader from '../../components/loader.vue';
import comment from '../../components/post/comment.vue'
export default {
    components: {
        back,
        loader,
        comment
    },
    data() {
        return {
            comment: {},
            loading: true
        }
    },
    mounted() {
        this.comment = JSON.parse(localStorage.getItem('comment'))

        setTimeout(() => {
            this.loading = false
        }, 100);
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>

    .view {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 40px) 50%;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 52px);
        overflow: auto;
        background-color: #edeef0;
        padding-top: 10px;
    }
    .l {
        margin-top: 50px;
    }
</style>